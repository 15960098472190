/* eslint-disable jsx-a11y/aria-role */
import React, { useContext, useState, useEffect } from 'react';
import TopBarProgress from "react-topbar-progress-indicator";
import LoadingBar from 'react-top-loading-bar';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { Groupsdb, Groupsstorage, GroupChatsdb } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, serverTimestamp, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import FileInput from "../Images/folder.png";
import { v4 as uuid } from "uuid";
import swal from '@sweetalert/with-react';

export const CreateGroup = (props) => {
  if (props.SendMsgCount === 0) {
    document.title = "Drake Chat • Create Groups"
  } else {
    document.title = "(" + props.SendMsgCount + ") Drake Chat • Create Groups"
  }
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [progresspercent, setProgressPercent] = useState();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [errorCode, setErrorCode] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [text, setText] = useState("");
  const [progresspercent, setProgressPercent] = useState();
  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);
  const [pfpFile, setPFPFile] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  //   const sleep = ms => new Promise(
  //     resolve => setTimeout(resolve, ms)
  //   );

  if (props.SendMsgCount === 0) {
    document.title = "Drake Chat • Create Group"
  } else {
    document.title = "(" + props.SendMsgCount + ") Drake Chat • Create Group"
  }

  const HandleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const GroupName = groupName;
    const File = e.target[1].files[0];
    const GroupId = uuid()
    const CombinedId = currentUser.uid + GroupId

    if (pfpFile !== null) {
      try {
        const storageRef = ref(Groupsstorage, "GroupDisplayPicture/" + GroupId);
        const uploadTask = uploadBytesResumable(storageRef, File);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const roundedprogress = Math.round(progress);
            setProgressPercent(roundedprogress);
          },
          (error) => {
            setErr(true);
            setLoading(false);
            setShowAlert(false);
            setErrorCode(error.code);
            // setErrorMessage(error.message);
            if (error.code === "auth/requires-recent-login") {
              swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
              signOut(auth)
              window.location.reload()
            }
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              await setDoc(doc(Groupsdb, "Groups", GroupId), {
                id: GroupId,
                Name: GroupName,
                displayPic: downloadURL,
                Admin: currentUser.displayName,
                DateCreated: combinedCurrentDateTime,
                DataChangedDate: {
                  displayNameChangedOn: combinedCurrentDateTime,
                  DisplayPictureChangedOn: combinedCurrentDateTime,
                },
                MembersCount: 1,
                Members: arrayUnion({
                  id: currentUser.uid,
                  displayName: currentUser.displayName,
                  JoinedOn: combinedCurrentDateTime,
                  photoURL: currentUser.photoURL,
                }),
                // messages: [],
              });
              await setDoc(doc(GroupChatsdb, "GroupChats", GroupId), {
                Messages: [],
              })
              await updateDoc(doc(Groupsdb, "GroupsData", currentUser.uid), {
                [CombinedId]: {
                  date: serverTimestamp(),
                  id: GroupId,
                  Name: GroupName,
                  displayPic: downloadURL,
                  ChatID: CombinedId,
                },
              });
            });
            setLoading(false);
            props.onChange("Groups");
            setText("");
            setGroupName("");
          })
      } catch (error) {
        setErr(true);
        setShowAlert(false);
        setLoading(false);
        setErrorCode(error.code);
        // setErrorMessage(error.message);
        if (error.code === "auth/requires-recent-login") {
          swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
          signOut(auth)
          window.location.reload()
        }
      }
    } else {
      try {
        await setDoc(doc(Groupsdb, "Groups", GroupId), {
          id: GroupId,
          Name: GroupName,
          displayPic: "https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/GroupDisplayPicture%2Fgroup.png?alt=media&token=1c56d7e6-fa50-4ee4-a01b-dbfef162a889",
          Admin: currentUser.displayName,
          DateCreated: combinedCurrentDateTime,
          DataChangedDate: {
            displayNameChangedOn: combinedCurrentDateTime,
            DisplayPictureChangedOn: combinedCurrentDateTime,
          },
          MembersCount: 1,
          Members: arrayUnion({
            id: currentUser.uid,
            displayName: currentUser.displayName,
            JoinedOn: combinedCurrentDateTime,
            photoURL: currentUser.photoURL,
          }),
          // messages: [],
        });
        await setDoc(doc(GroupChatsdb, "GroupChats", GroupId), {
          Messages: [],
        })
        await updateDoc(doc(Groupsdb, "GroupsData", currentUser.uid), {
          [CombinedId]: {
            date: serverTimestamp(),
            id: GroupId,
            Name: GroupName,
            displayPic: "https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/GroupDisplayPicture%2Fgroup.png?alt=media&token=1c56d7e6-fa50-4ee4-a01b-dbfef162a889",
            ChatID: CombinedId,
          },
        });
        setLoading(false);
        props.onChange("Groups");
        setText("");
        setGroupName("");
      } catch (error) {
        setErr(true);
        setShowAlert(false);
        setLoading(false);
        setErrorCode(error.code);
        // setErrorMessage(error.message);
        if (error.code === "auth/requires-recent-login") {
          swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
          signOut(auth)
          window.location.reload()
        }
      }
    }
  }

  const handleImageChange = e => {
    const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
    if (!validExtensions.includes(e.target.files[0].type)) {
      swal("File Format Not Supported")
      setText("");
      setLoading(false);
      setPFPFile(null);
      return false
    } else {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        swal("File Size Should Be Less Than 10 MB")
        setText("");
        setLoading(false);
        setPFPFile(null);
        return false
      } else {
        setText(e.target.files[0].name)
        setPFPFile(e.target.files[0])
      }
    }
  }

  TopBarProgress.config({
    barThickness: 8
  });

  if (!currentUser) {
    navigate("/")
  }

  const Group = () => {
    props.rcw()
    props.onChange("HOME")
    // setForwardMsg(false)
    // setToolsInput("Input")
  }

  return (
    <>
      {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
      <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4">
        <header className="flex justify-between px-2 md:px-4 lg:px-6">
          <div className="flex gap-8 align-center justify-center text-center">
            <span className="my-auto cursor-pointer " id="close-chat" onClick={Group}>
              <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
            </span>
          </div>
        </header>
        <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 !pr-2 rounded-2xl md:p-4 lg:p-6 justify-center align-center text-center items-center">
          {!loading && showAlert &&
            <div class="bg-transparent text-center py-4 lg:px-4 rounded-full">
              <div class="p-4 bg-indigo-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="swal">
                <span class="font-semibold mr-2 text-left flex-auto">Group Created</span>
              </div>
            </div>
          }
          <div className="w-full h-full relative">
            <div className="w-full h-[90%] flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden justify-center align-center text-center items-center">
              <h1 className="font-extrabold text-base md:text-xl break-all whitespace-nowrap dark:text-white">CREATE GROUPS</h1>
              <form onSubmit={HandleFormSubmit} className="justify-center align-center text-center items-center flex flex-col p-8 w-full" style={{ "border-radius": "44px", "background-color": "rgba(0,0,0,0.2)", "backdrop-filter": "blur('44px')" }}>
                <input type="text" onChange={(e) => setGroupName(e.target.value)} placeholder="PLEASE ENTER A NAME" required className="rounded-full w-full p-2 active:border-none focus:border-none" style={{ justifyContent: "center", alignItems: "center", textAlign: "center", border: "none", backgroundColor: "rgba(255,255,255,0.2)", backdropFilter: "blur('44px')", outline: "none", color: "#fff", fontWeight: "900" }} id="Input" />
                <br />
                <input accept="image/png, image/jpeg" type="file" placeholder="Enter Your Profile Picture" id="File" style={{ display: "none" }} onChange={(e) => handleImageChange(e)} className="bg-transparent backdrop-blur-3xl" />
                <label htmlFor="File" className="justify-center flex flex-col items-center text-center">
                  <img src={FileInput} style={{ height: '40px', width: '40px' }} alt="Select A Group DP" className="cursor-pointer" />
                  <span className="font-extrabold text-white text-xl uppercase mt-2 cursor-pointer">Upload A Group Display Picture</span>
                </label>
                <textbox readonly className="text-slate-800 dark:text-white">{text}</textbox>{loading && <button disabled="true" className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>CREATING GROUP</button>}
                {!loading && <button className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>CREATE</button>}
                {!loading && err && <span style={{ color: 'red' }}>Something Went Wrong!!</span>}
                {!loading && err && <span style={{ color: 'red' }}>{errorCode}</span>}
              </form>
            </div>
          </div>
        </main>
      </section>
    </>
  )
}