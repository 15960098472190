import React from "react";
import GroupMessage from "./GroupMessage";

const GroupMessages = (props) => {

    return (
        <>
            {props.Messages.map(M => (
                <GroupMessage Message={M} key={M.id} onChange={(value) => props.mid(value)} MessageText={(value) => props.mtxt(value)} MessageSenderID={(value) => props.msendid(value)} MessageReceiverID={(value) => props.mreceid(value)} MessageDoc={(value) => props.mdoc(value)} MessageImg={(value) => props.mimg(value)} MessageAudio={(value) => props.maud(value)} MessageVideo={(value) => props.mvid(value)} MessageSentTime={(value) => props.mtime(value)} ChangeFunction={(value) => props.UpdateFunction(value)} ShowOptions={(value) => props.Options(value)} ShowOptionsState={props.ShowOptionsState} MessageUnSeen={props.MessageUnSeen} InChatWindow={props.InChatWindow} MessageData={props.msgData} CurrentChatID={props.CurrentChatId} SelectedGroupData={props.SelectedGroupData} />
            ))}
        </>
    )
}

export default GroupMessages
