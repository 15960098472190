/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
// import { doc, onSnapshot } from "firebase/firestore";
// import { ChatContext } from "../context/ChatContext";
// import { db } from "../firebase";
import Message from "./Message";
import { useEffect } from "react";
import * as LoadingSpinner from "react-spinners-kit";

const Messages = (props) => {

  const [numberOfChats, setNumberOfChats] = useState(10)
  const LengthOfArray = props.messages.length
  const MessagesToShow = LengthOfArray - numberOfChats

  useEffect(() => {
    setNumberOfChats(numberOfChats + 10)
    props.ChangeMoreChatsLoading(false)
  }, [props.RenderMoreChats])

  return (
    <>
      {/* <div className="Messages"> */}
      {/* {messages.map(m => (
        props.messagedata(m)
      ))} */}
      {/* <LoadingSpinner size={30} color="#ff0058" loading={loading} /> */}
      {props.MoreChatLoading ?
        <div className="w-full h-12 mt-2 flex justify-center text-center items-center align-center">
          <LoadingSpinner.ImpulseSpinner size={60} color="#ff0058" loading={props.MoreChatLoading} />
        </div>
        : null
      }
      {props.messages?.filter((item, idx) => idx >= MessagesToShow).map(m => (
        <Message message={m} key={m.id} onChange={(value) => props.mid(value)} MessageText={(value) => props.mtxt(value)} MessageSenderID={(value) => props.msendid(value)} MessageReceiverID={(value) => props.mreceid(value)} MessageDoc={(value) => props.mdoc(value)} MessageImg={(value) => props.mimg(value)} MessageAudio={(value) => props.maud(value)} MessageVideo={(value) => props.mvid(value)} MessageSentTime={(value) => props.mtime(value)} ChangeFunction={(value) => props.UpdateFunction(value)} ShowOptions={(value) => props.Options(value)} ShowOptionsState={props.ShowOptionsState} MessageUnSeen={props.MessageUnSeen} InChatWindow={props.InChatWindow} MessageData={props.msgData} CurrentChatID={props.CurrentChatId} Scroll={props.ScrollDown} SendReactions={props.SendReactions} LikeAMessage={props.LikeMessage} />
      ))}
      {/* </div> */}
    </>
  )
}

export default Messages
