/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Home from "./pages/Home";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import "./App.css"
import {
  BrowserRouter,
  useLocation,
  Route,
  Navigate,
  Routes
} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import PageNotFound from './components/PageNotFound';
import TopBarProgress from "react-topbar-progress-indicator";
// import { User } from './pages/User';
// import { Profile } from './pages/Profile';
// import { ChangeChatBackground } from './pages/ChangeChatBackground';
// import { ChangeUsername } from './pages/ChangeUsername';
// import { ChangeEmail } from './pages/ChangeEmail';
// import { ChangePassword } from './pages/ChangePassword';
// import { ChangeProfilePicture } from './pages/ChangeProfilePicture';
// import Encrypt_Decrypt from './components/Encrypt_Decrypt';
import { ResetPassword } from './pages/ResetPassword';
// import { DeleteUser } from './pages/DeleteUser';

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState("")
  const location = useLocation()

  useEffect(() => {
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  )
}

function App() {
  const { currentUser } = useContext(AuthContext);
  // console.log(currentUser)

  const ProtectedRouteHome = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/Login" />;
    }
    return children
  };
  // const ProtectedRouteLogin = ({ children }) => {
  //   if (currentUser) {
  //     return <Navigate to="/" />;
  //   }
  //   return children
  // };
  // const ProtectedRouteRegister = ({ children }) => {
  //   if (currentUser) {
  //     return <Navigate to="/" />;
  //   }
  //   return children
  // };

  TopBarProgress.config({
    barThickness: 8
  });


  return (
    <BrowserRouter>
      <CustomSwitch>
        <Route index element={<ProtectedRouteHome><Home /></ProtectedRouteHome>} />
        <Route path="/login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        {/* <Route path="/User/:UserId/:UserName/:EMail/:Status/" element={<User />}/>
        <Route path="/Profile" element={<Profile />}/>
        <Route path="/Profile/UpdateChatBackgroundImage" element={<ChangeChatBackground />}/>
        <Route path="/Profile/UpdateUsername" element={<ChangeUsername />}/>
        <Route path="/Profile/UpdateEmail" element={<ChangeEmail />}/>
        <Route path="/Profile/ResetPassword" element={<ChangePassword />}/>
        <Route path="/Profile/UpdateProfilePicture" element={<ChangeProfilePicture />}/>
        <Route path="/Profile/DeleteUser" element={<DeleteUser />}/>*/}
        {/* <Route path="/Encrypt_Decrypt" element={<Encrypt_Decrypt />} /> */}
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route exact path="*" element={<PageNotFound />} />
      </CustomSwitch>
    </BrowserRouter>
  );
}

export default App;