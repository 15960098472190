/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { doc, onSnapshot, collectionGroup, FieldValue, arrayRemove, arrayUnion, updateDoc, deleteField, deleteDoc, collection, where, query, getDocs, setDoc, getDoc, serverTimestamp, } from "firebase/firestore";
import { MessageDatadb, MessageReactiondb, TypingStatusdb, db } from "../firebase";
// import { onSnapshot } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
// import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Document, Page } from 'react-pdf';
import ReactPlayer from 'react-player'
import * as LoadingSpinner from "react-spinners-kit";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";
import { BsEmojiSmile } from 'react-icons/bs';
// import { useSelector, useDispatch } from "react-redux";
import swal from '@sweetalert/with-react';
// import { ShowTools, ShowInput } from "../actions/index";
import { useLongPress } from 'use-long-press';
// import Linkify from "linkify-react";
import { Anchorme } from 'react-anchorme'
// import NotificationSound from '../Audio/NotificationSound.mp3'
import CryptoJS from "crypto-js";
import { v4 as uuid } from "uuid";

const Message = ({ message, change, onChange, MessageText, MessageSenderID, MessageReceiverID, MessageDoc, MessageImg, MessageAudio, MessageVideo, ShowOptions, MessageSentTime, ShowOptionsState, MessageUnSeen, InChatWindow, MessageData, CurrentChatID, Scroll, SendReactions, LikeAMessage }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [loading, setLoading] = useState(false);
  // const [copied, setCopied] = useState("Copy");
  // const [unsend, setUnsend] = useState("Unsend");
  // const [msg, setMsg] = useState("");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [activeClassname, setActiveClassname] = useState("");
  const [emojiSelector, setEmojiSelector] = useState(false);
  const [emojiData, setEmojiData] = useState(null);
  // const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiReactedUserName, setEmojiReactedUserName] = useState("");
  const [emojiReactedMessageID, setEmojiReactedMessageID] = useState("");
  // const [userReactionUpdated, setUserReactionUpdated] = useState(0);

  // const dispatch = useDispatch();

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [Scroll]);

  // useEffect(() => {
  //   ref.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  // }, [message]);

  // const ScrollDownIntoView = () => {
  //   ref.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  // }

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [loading]);

  const ShowMoreOptionsToolbox = async () => {
    if (showMoreOptions) {
      setShowMoreOptions(false)
      setActiveClassname("")
      setEmojiSelector(false)
    } else {
      setShowMoreOptions(true)
      setActiveClassname("ToolBoxIsActive")
    }
  }
  const CloseMoreOptionsToolbox = async () => {
    if (showMoreOptions) {
      setShowMoreOptions(false)
      setEmojiSelector(false)
    }
  }

  const ReactToMessage = async () => {
    if (emojiSelector) {
      setEmojiSelector(false)
    } else {
      setEmojiSelector(true)
    }
  }

  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // const OpenOptionsWindow = () => {
  //   swal(
  //     <ul className="flex flex-col items-center justify-center text-justify space-y-4 text-white font-extrabold" id="MsgContainer">
  //       <li id="REACT" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-grin-alt dark:text-white text-white font-extrabold flex transition duration-300"></i>REACT</li>
  //       <li id="FORWARD" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-redo dark:text-white text-white font-extrabold flex transition duration-300"></i>FORWARD</li>
  //       {message.document ? <li id="OPENDOC" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li> : null}
  //       {message.img ? <li id="OPENIMG" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li> : null}
  //       {message.music ? <li id="OPENAUD" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li> : null}
  //       {message.video ? <li id="OPENVID" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li> : null}
  //       {message.text === "" ? null : <li id="COPY" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-slate-800 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-duplicate dark:text-white text-white font-extrabold flex transition duration-300"></i>COPY</li>}
  //       {message.senderId === currentUser.uid && <li id="DELETE" className="text-white w-1/3 h-10 flex gap-2 items-center justify-center text-justify p-4 bg-red-400 rounded-2xl font-extrabold dark:text-white cursor-pointer"><i className="text-justify f justify-center items-centeri fi-rr-trash dark:text-white text-white font-extrabold flex transition duration-300"></i>UNSEND</li>}
  //     </ul>
  //     , {
  //       button: "CLOSE",
  //       html: true,
  //     }
  //   );
  // }

  const CombinedFunction = () => {
    ShowOptions(true)
    // change()
    // OpenOptionsWindow()
    onChange(message.id)
    MessageText(message.text)
    MessageDoc(message.document)
    MessageImg(message.img)
    MessageAudio(message.music)
    MessageVideo(message.video)
    MessageSenderID(message.senderId)
    MessageReceiverID(message.receiverId)
    const CombinedMessageDateTime = message.MessageDate + " " + message.MessageTime
    message.MessageDate ? MessageSentTime(CombinedMessageDateTime) : MessageSentTime(message.MessageSentTimestamp)
  }

  // useEffect(() => {
  //   const updateMessageStatus = async () => {
  //     await updateDoc(doc(db, "MessageData", currentUser.uid), {
  //       [data.chatId]: deleteField(),
  //     });
  //   }
  //   updateMessageStatus()
  // }, [message]);

  // console.log(InChatWindow)

  if (InChatWindow !== data.chatId) {
    updateDoc(doc(MessageDatadb, "MessageData", currentUser.uid), {
      MessageUnSeen: arrayRemove(data.user.uid),
      InChatWindow: data.chatId,
    });
  }
  // if (CurrentChatID !== data.chatId) {
  //   updateDoc(doc(TypingStatusdb, "TypingStatus", currentUser.uid), {
  //     CurrentChatID: data.chatId,
  //   })
  // }

  const OpenImage = () => {
    swal(
      <img src={message.img} alt={"IMAGE : " + message.id} className="w-full h-full md:h-3/5 md:w-3/5 rounded-xl" />, { button: "CLOSE", }
    )
  }

  const StopVideoPlay = () => {
    document.getElementById("Video").pause();
    document.getElementById("Video").muted = true;
    document.getElementById("Video").onplay = document.getElementById("Video").pause();
    document.getElementById("Video").onPlay = document.getElementById("Video").pause();
  }

  const OpenVideo = () => {
    // setVideoPlayState(true)
    // document.getElementById("Video").pause();
    // document.getElementById("Video").muted = true;
    // document.getElementById("Video").pause();
    // document.getElementById("Video").muted = true;
    swal(
      <>
        <video controls className="w-3/5 md:h-3/5 md:w-3/5 flex justify-center items-center rounded-3xl" id="videoPlayer">
          <source src={message.video} type="video/mp4" />
        </video>
      </>
      , {
        button: "CLOSE",
      }
    ).then((stop) => {
      if (stop) {
        document.getElementById("videoPlayer").pause();
      }
    });
    document.getElementById("Video").pause();
    document.getElementById("Video").muted = true;
    document.getElementById("Video").pause();
    document.getElementById("Video").muted = true;
  }

  const RemoveReaction = (val) => {
    swal({
      title: "Remove Reaction",
      text: "Are You Sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDoc(doc(MessageReactiondb, "Reaction", val.ReactionId));
        setEmojiData("")
        setEmojiReactedUserName("")
        setEmojiReactedMessageID("")
        // swal("Reaction Deleted", {
        //   icon: "success",
        // });
      }
    });
  }

  const ShowReaction = (MRData) => {
    if (MRData.NameOfUserReactedOnMessage === currentUser.displayName) {
      swal(
        <>
          <div className="flex flex-col justify-center text-center align-center items-center">
            <h1 className="bg-blue-300 p-2 rounded-full font-extrabold w-full">
              <Emoji unified={MRData.EmojiCode} emojiStyle={EmojiStyle.NATIVE} size={20} /> : {MRData.NameOfUserReactedOnMessage}
            </h1>
          </div>
        </>, {
        buttons: ["CLOSE", "DELETE"]
      }
      ).then((Delete) => {
        if (Delete) {
          RemoveReaction(MRData)
        }
      });
    } else {
      swal(
        <>
          <div className="flex flex-col justify-center text-center align-center items-center">
            <h1 className="bg-blue-300 p-2 rounded-full font-extrabold w-full">
              <Emoji unified={MRData.EmojiCode} emojiStyle={EmojiStyle.NATIVE} size={20} /> : {MRData.NameOfUserReactedOnMessage}
            </h1>
          </div>
        </>, {
        button: "CLOSE"
      }
      )
    }
  }

  const bind = useLongPress(() => {
    onChange(message.id)
    MessageText(message.text)
    MessageDoc(message.document)
    MessageImg(message.img)
    MessageAudio(message.music)
    MessageVideo(message.video)
    MessageSenderID(message.senderId)
    MessageReceiverID(message.receiverId)
    const CombinedMessageDateTime = message.MessageDate + " " + message.MessageTime
    message.MessageDate ? MessageSentTime(CombinedMessageDateTime) : MessageSentTime(message.MessageSentTimestamp)
    navigator.vibrate(40);
    ShowOptions(true);
  });

  const HideOptions = () => {
    if (ShowOptionsState === false) {
      return false
    } else {
      ShowOptions(false);
    }
  }

  // document.getElementById("ShowOptions").addEventListener("click", HideOptions());

  const options = {
    /* … */
  };

  const LikeMessage = async (val) => {
    onChange(val)
    LikeAMessage(val)
    // const UniqueIDForReaction = uuid()
    // await setDoc(doc(MessageReactiondb, "Reaction", UniqueIDForReaction), {
    //   ReactedMessageID: val,
    //   EmojiCode: "2764-fe0f",
    //   NameOfUserReactedOnMessage: currentUser.displayName,
    //   ChatId: data.chatId,
    //   ReactionId: UniqueIDForReaction,
    // });
  }

  // const componentDecorator = (href, text, key) => (
  //   <a className="linkify__text" href={href} key={key} target="_blank" rel="noreferrer" style={{ color: '#ff0058' }}>
  //     {text}
  //   </a>
  // );

  // const secretPass = "QW123ERTYUIOP456XkhZG4DPIVPAHZXC789VBNMLYROC12T0fW2t2WASDFGHJKL";
  // const [decrptedData, setDecrptedData] = useState("");

  // const Emojis = []
  // const ReactedMessageId = []
  // const ReactionData = []

  // const q = ;

  // onSnapshot(query(collection(MessageReactiondb, "MessageReaction"), where("ReactedMessageID", "==", message.id)), (querySnapshot) => {
  //   querySnapshot.forEach((doc) => {
  //     Emojis.push(doc.data().Emoji);
  //   });
  // });

  // const q = query(collection(MessageReactiondb, "MessageReaction"), where("ReactedMessageID", "==", message.id));
  // const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //   querySnapshot.forEach((doc) => {
  //     ReactionData.push(doc.data())
  //     Emojis.push(doc.data().Emoji)
  //     ReactedMessageId.push(doc.data().ReactedMessageID)
  //   });
  // });

  // useEffect(() => {
  //   ReactedMessageId.map(r => (
  //     console.log(r.includes("d94224b9-1b6f-442e-9a98-5c6ae60a3320") === true ? "Yes" : "No")
  //   ))
  // }, [ReactedMessageId])

  // console.log(SendReactions)

  const ReactionMessageID = [];

  SendReactions !== "NO REACTIONS" &&
    SendReactions.map(MID => (
      ReactionMessageID.includes(MID.ReactedMessageID) === false ? ReactionMessageID.push(MID.ReactedMessageID) : null
    ))


  return (
    <>
      {/* {
        onSnapshot(doc(db, "DeletedMessages", message.id), (doc) => {
          setMsg(doc.data().MessageID)
        })
      } */}

      {/* {
        onSnapshot(doc(MessageReactiondb, "MessageReaction", message.id), (doc) => {
          setEmojiData(doc.data().EmojiCode)
          setEmojiReactedUserName(doc.data().NameOfUserReactedOnMessage)
          setEmojiReactedMessageID(doc.data().ReactedMessageID)
        })
      } */}

      {/* {
        onSnapshot(query(collection(MessageReactiondb, "MessageReaction"), where("ReactedMessageID", "==", message.id)), (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            Emojis.push(doc.data().Emoji)
            ReactedMessageId.push(doc.data().ReactedMessageID)
          })
        })
      } */}
      {/* {msg === message.id
        ?
        null
        : */}
      <>
        {message.senderId === currentUser.uid ?
          <div id="MessageBOX" className={`LongPressDiv flex gap-2 flex-row-reverse Message ${message.senderId === currentUser.uid && "Owner"} ${message.senderId === data.user.uid && "User"} ${message.id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={HideOptions}>
            <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-9 lg:h-10">
              <img className="h-full w-full object-cover block rounded-full" src={
                message.senderId === currentUser.uid
                  ? currentUser.photoURL
                  : data.user.photoURL
              } alt="user" />
            </picture>
            <div className="w-full">
              {/* <header className="flex items-center gap-2 flex-row-reverse text-center">
                  <h6 className="text-sm font-extrabold dark:text-white">
                    {
                      message.senderId === currentUser.uid
                        ? currentUser.displayName
                        : data.user.displayName
                    }
                  </h6>
                  {message.MessageDate ? <time className="text-[.6rem] dark:text-slate-200">{message.MessageDate} {message.MessageTime}</time> : <time className="text-[.6rem] dark:text-slate-200">{message.MessageSentTimestamp}</time>}
                  {message.forward
                    ?
                    <>
                      <h6 className="text-sm font-semibold dark:text-white bg-purple-400 py-1 px-2 rounded-full">
                        FORWARDED
                      </h6>
                    </>
                    :
                    null
                  }
                </header> */}
              {/* {msg === message.id ? */}
              {/* // <>
                //   <div className="flex items-center gap-2 flex-row-reverse" id="MsgContainer">
                //     <p id="MsgContainer"
                //       className="text-white text-right text-xs mt-1 py-2 px-4 bg-red-400 rounded-2xl rounded-tr-none shadow-2xl shadow-violet-500/25 max-w-[45%] w-max md:max-w-[60%]">
                //       THIS MESSAGE WAS DELETED
                //     </p>
                //   </div>
                // </>
                // null
                // : */}
              <div className="flex flex-col">
                {message.text === "" ? null :
                  <>
                    {/* {setDecrptedData(JSON.parse(CryptoJS.AES.decrypt(message.text, secretPass).toString(CryptoJS.enc.Utf8)))} */}
                    <div className="flex items-center gap-2 flex-row-reverse" id="MsgContainer">
                      <p {...bind()} onDoubleClick={() => LikeMessage(message.id)}
                        className="select_none text-white break-words text-xs mt-1 py-2 px-4 bg-violet-600 rounded-2xl rounded-tr-none shadow-2xl shadow-violet-500/25 max-w-[60%] w-max md:max-w-[70%]" id={message.id}>
                        {/* {message.text.includes('http') ? <><a href={message.text} target="_blank"><p className="text-green-200 font-extrabold">{message.text}</p></a></> : message.text} */}
                        {/* {message.text.includes('http') ? <p id="MSGLINK" dangerouslySetInnerHTML={{ __html: hello }}></p> : message.text} */}
                        {/* {message.text.includes('http') ? message.text.replace("https://chat.webdevdrake.ga", <a href="https://chat.webdevdrake.ga">https://chat.webdevdrake.ga</a>) : message.text} */}
                        {/* {message.text.includes('http') ? replaceURLs(message.text) : message.text} */}
                        {/* <Linkify as="p" options={options} properties={{ target: '_blank', style: { color: '#177c8a' } }} componentDecorator={componentDecorator}>
                            {message.text}
                          </Linkify> */}
                        <Anchorme target="_blank" rel="noreferrer noopener" style={{ fontWeight: '900' }}>
                          {/* {decrptedData} */}
                          {message.text}
                        </Anchorme>
                      </p>
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                    </div>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-right right-0 justify-end w-full rounded-full z-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>}
                {message.img &&
                  <>
                    <aside className="flex w-full h-full md:max-w-[100%] space-x-2 justify-end flex-row items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <picture {...bind()} onDoubleClick={() => LikeMessage(message.id)} className="w-3/5 h-3/5 md:h-1/5 md:w-1/5 cursor-pointer">
                        <img className="rounded-lg h-full w-full object-fit" src={message.img} alt="img" onClick={OpenImage} />
                      </picture>
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-right right-0 justify-end w-full rounded-full z-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
                {message.music &&
                  <>
                    <aside className="flex w-full h-full space-x-2 md:max-w-[100%] justify-end flex-row items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <AudioPlayer {...bind()} onDoubleClick={() => LikeMessage(message.id)} src={message.music} controls style={{ "border-radius": "20px" }} />
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-right right-0 justify-end w-full rounded-full z-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
                {message.video &&
                  <>
                    <aside className="flex w-full h-full space-x-2 md:max-w-[100%] justify-end flex-row items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <video {...bind()} onDoubleClick={() => LikeMessage(message.id)} className="w-3/5 md:h-1/5 md:w-1/5 rounded-2xl cursor-pointer" id="Video" onClick={OpenVideo}>
                        <source src={message.video} type="video/mp4" onClick={OpenVideo} />
                      </video>
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-right right-0 justify-end w-full rounded-full z-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
                {message.document &&
                  <>
                    <aside className="flex w-full h-full space-x-2 md:max-w-[100%] justify-end flex-row items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <iframe {...bind()} onDoubleClick={() => LikeMessage(message.id)} src={message.document} title="Document" frameborder="0" height="300" className="w-4/5 rounded-2xl"></iframe>
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-right right-0 justify-end w-full rounded-full z-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
              </div>
              {/* } */}
            </div>
          </div>
          :
          <div id="MessageBOX" className={`LongPressDiv flex gap-2 Message ${message.senderId === currentUser.uid && "Owner"} ${message.senderId === data.user.uid && "User"} ${message.id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={HideOptions}>
            <textarea id="TextArea" style={{ display: 'none' }}></textarea>
            <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-9 lg:h-10">
              <img className="h-full w-full object-cover block rounded-full" src={
                message.senderId === currentUser.uid
                  ? currentUser.photoURL
                  : data.user.photoURL
              } alt="user" />
            </picture>
            <div className="w-full">
              {/* <header className="flex items-center gap-2">
                  <h6 className="text-sm font-extrabold dark:text-white">{message.senderId === currentUser.uid
                    ? currentUser.displayName
                    : data.user.displayName}</h6>
                  {message.MessageDate ? <time className="text-[.6rem] dark:text-slate-200">{message.MessageDate} {message.MessageTime}</time> : <time className="text-[.6rem] dark:text-slate-200">{message.MessageSentTimestamp}</time>}
                  {message.forward
                    ?
                    <>
                      <h6 className="text-sm font-semibold dark:text-white bg-purple-400 py-1 px-2 rounded-full">
                        FORWARDED
                      </h6>
                    </>
                    :
                    null
                  }
                </header> */}
              {/* {msg === message.id ?
                  <>
                    <div className="flex items-center gap-2 flex-row" id="MsgContainer">
                      <p id="MsgContainer" className="text-xs mt-1 py-2 px-4 text-white bg-red-400 rounded-2xl rounded-tl-none max-w-[45%] w-max md:max-w-[60%]">
                        THIS MESSAGE WAS DELETED
                      </p>
                    </div>
                  </>
                  : */}
              <div>
                {message.text === "" ? null :
                  <>
                    <div className="flex items-center gap-2 flex-row" id="MsgContainer">
                      <p {...bind()} onDoubleClick={() => LikeMessage(message.id)} className="select_none text-xs break-words mt-1 py-2 px-4 bg-white rounded-2xl rounded-tl-none max-w-[60%] w-max md:max-w-[70%]" id={message.id}>
                        {/* <Linkify as="p" options={options} properties={{ target: '_blank', style: { color: '#177c8a' } }} componentDecorator={componentDecorator}>
                              {message.text}
                            </Linkify> */}
                        <Anchorme target="_blank" rel="noreferrer noopener" style={{ fontWeight: '900' }}>
                          {/* {decrptedData} */}
                          {message.text}
                        </Anchorme>
                      </p>
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                    </div>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-left left-0 justify-start w-full rounded-full z-10 bottom-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>}
                {message.img &&
                  <>
                    <aside className="flex mt-2 w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <picture {...bind()} onDoubleClick={() => LikeMessage(message.id)} className="w-3/5 h-3/5 md:h-1/5 md:w-1/5 cursor-pointer">
                        <img className="rounded-lg h-full w-full object-fit" src={message.img} alt="img" onClick={OpenImage} />
                      </picture>
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-left left-0 justify-start w-full rounded-full z-10 bottom-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
                {message.music &&
                  <>
                    <aside className="flex mt-2 w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <AudioPlayer {...bind()} onDoubleClick={() => LikeMessage(message.id)} src={message.music} controls style={{ "border-radius": "20px" }} />
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-left left-0 justify-start w-full rounded-full z-10 bottom-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
                {message.video &&
                  <>
                    <aside className="flex mt-2 w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <video {...bind()} onDoubleClick={() => LikeMessage(message.id)} className="w-3/5 md:h-1/5 md:w-1/5  rounded-2xl cursor-pointer" id="Video" onClick={OpenVideo}>
                        <source src={message.video} type="video/mp4" onClick={OpenVideo} />
                      </video>
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-left left-0 justify-start w-full rounded-full z-10 bottom-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
                {message.document &&
                  <>
                    <aside className="flex w-full space-x-2 h-full md:max-w-[100%] justify-end flex-row-reverse items-center" id="MsgContainer">
                      <span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" style={{ "font-weight": "900" }}></span><span className="flex items-center justify-center cursor-pointer text-slate-800 dark:text-white" id="MoreOptions" onClick={CombinedFunction} style={{ "font-weight": "900" }}>•••</span>
                      <iframe {...bind()} onDoubleClick={() => LikeMessage(message.id)} src={message.document} title="Document" frameborder="0" height="300" className="w-4/5 rounded-2xl"></iframe>
                    </aside>
                    {ReactionMessageID.map(RMID => (
                      RMID === message.id ?
                        <>
                          <div id="ReactionByTheUser" className="flex text-left left-0 justify-start w-full rounded-full z-10 bottom-10">
                            {SendReactions !== "NO REACTIONS" &&
                              SendReactions.map(MR => (
                                MR.ReactedMessageID === message.id ?
                                  <>
                                    <div className="bg-slate-900 cursor-pointer rounded-full max-w-max max-h-max p-1" onClick={() => ShowReaction(MR)}>
                                      <Emoji
                                        unified={MR.EmojiCode}
                                        emojiStyle={EmojiStyle.NATIVE}
                                        size={20}
                                      />
                                    </div>
                                  </>
                                  : null
                              ))}
                          </div>
                        </>
                        : null
                    ))}
                    <h1 ref={ref} className="invisible"></h1>
                  </>
                }
              </div>
              {/* } */}
            </div>
          </div>
        }
      </>
      {/* } */}
    </>
  );
};

export default Message;