/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import {
    onSnapshot,
    doc
} from "firebase/firestore";
import { GroupChatsdb, Groupsdb } from "../firebase";
import { ChatContext } from "../context/ChatContext";
import { AuthContext } from "../context/AuthContext";
// import moment from 'moment';
import LoadingBar from 'react-top-loading-bar';
import * as LoadingSpinner from "react-spinners-kit";
import Forward from './Forward'
import GroupInput from "./GroupInput";
import GroupMessages from "./GroupMessages";

const Groups = (props) => {
    const { data } = useContext(ChatContext);
    const [loading, setLoading] = useState(false);
    const [progresspercent, setProgressPercent] = useState();
    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);
    const { currentUser } = useContext(AuthContext);

    if (props.SelectedGroup === null) {
        if (props.SendMsgCount === 0) {
            document.title = "Drake Chat • Groups"
        } else {
            document.title = "(" + props.SendMsgCount + ") Drake Chat • Groups"
        }
    } else {
        if (props.SendMsgCount === 0) {
            document.title = "Drake Chat • " + props.SelectedGroup.Name;
        } else {
            document.title = "(" + props.SendMsgCount + ") Drake Chat • " + props.SelectedGroup.Name;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            var current = new Date();
            var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
            var CurrentDate = current.toLocaleDateString();
            setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const [sendMsgId, setSendMsgId] = useState(null)
    const [sendMsgReceiverID, setSendMsgReceiverID] = useState(null)
    const [sendMsgText, setSendMsgText] = useState(null)
    const [sendMsgSenderID, setSendMsgSenderID] = useState(null)
    const [sendMsgDoc, setSendMsgDoc] = useState(null)
    const [sendMsgImg, setSendMsgImg] = useState(null)
    const [sendMsgAud, setSendMsgAud] = useState(null)
    const [sendMsgVid, setSendMsgVid] = useState(null)
    const [sendMsgTime, setSendMsgTime] = useState(null)
    // const [emptyMsg, setEmptyMsg] = useState(false)
    const [messages, setMessages] = useState([])
    const [forwardMsg, setForwardMsg] = useState(false)
    const [fwdmsgcontent, setFwdmsgcontent] = useState(null)
    const [actionName, setActionName] = useState("")
    const [chatLoading, setChatLoading] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [fwdmsgvalue, setFwdmsgvalue] = useState(null)
    const [functionChange, setFunctionChange] = useState(null)
    const [currentChatID, setCurrentChatID] = useState("")
    const [typingStatus, setTypingStatus] = useState(false)

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const HomeScreen = () => {
        props.rcw()
        setForwardMsg(false)
        // setToolsInput("Input")
    }

    const GroupSettings = () => {
        props.onChange("GRPSTGS")
        setForwardMsg(false)
    }

    if (props.scw === false) {
        if (props.SendMsgCount === 0) {
            document.title = "Drake Chat • Groups"
        } else {
            document.title = "(" + props.SendMsgCount + ") Drake Chat • Groups"
        }
    }

    const ForwardMsgTo = (fwdmsg) => {
        setForwardMsg(true)
        setFwdmsgvalue(fwdmsg)
    }

    const ForwardMsgContentTo = (fwdmsgcontent) => {
        setForwardMsg(true)
        setFwdmsgcontent(fwdmsgcontent)
    }

    useEffect(() => {
        setLoading(true)
        const getGroups = () => {
            const unsub = onSnapshot(doc(Groupsdb, "Groups", props.SelectedGroup.id), (doc) => {
                props.GetSelectedGroupData(doc.data());
                setLoading(false)
            });
            return () => {
                unsub();
            };
        };
        props.SelectedGroup.id && getGroups();
    }, [props.SelectedGroup.id]);

    // console.log(props.SelectedGroupData.Members)
    // props.SelectedGroupData.Members.map(M => (console.log(M)))

    useEffect(() => {
        setChatLoading(true);
        const unSub = onSnapshot(doc(GroupChatsdb, "GroupChats", props.SelectedGroup.id), (doc) => {
            doc.exists() && setMessages(doc.data().Messages);
            // console.log(doc.data().Messages)
            const SetSleep = async () => {
                await sleep(100)
                setChatLoading(false);
            };
            SetSleep()
        });

        return () => {
            unSub();
        };
    }, [props.SelectedGroup.id]);


    const ShowOptions = (val) => {
        setShowOptions(val);
    }

    const PerformAction = (val) => {
        setShowOptions(false);
        setActionName(val);
    }

    const HideOptions = () => {
        if (showOptions === false) {
            return false
        } else {
            ShowOptions(false);
        }
    }


    return (
        <>
            {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
            {props.scw === false ? null :
                <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4" id="chat">
                    <header className="flex items-center justify-between px-2 md:px-4 lg:px-6">
                        <div className="flex gap-2 items-center">
                            <span className="my-auto cursor-pointer" id="close-chat" onClick={HomeScreen}>
                                <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
                            </span>
                            <picture className="relative flex h-8 aspect-square cursor-pointer sm:h-12" id="open-detail">
                                <img className="h-full w-full object-cover block rounded-full" src={props.SelectedGroup.displayPic} alt="user" />
                            </picture>
                            <div className="text-xl cursor-pointer " onClick={GroupSettings}>
                                <h2 className="font-extrabold whitespace-nowrap dark:text-white">{props.SelectedGroup.Name}</h2>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <span className="flex cursor-pointer justify-end right-0 content-end" id="close-chat" onClick={GroupSettings}>
                                <i className="fi fi-ss-settings flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
                            </span>
                        </div>
                    </header>
                    <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 !pr-2 rounded-2xl md:p-4 lg:p-6">
                        <div className="w-full h-full relative">
                            {forwardMsg
                                ?
                                <Forward SelectedGroupData={props.SelectedGroupData} SendChatData={props.chatdata} messages={messages} CurrentMessageValue={fwdmsgvalue} CurrentMsgContent={fwdmsgcontent} ExitForwardMessage={() => setForwardMsg(false)} SendGroupsData={props.GroupsData} />
                                :
                                <>
                                    {chatLoading ?
                                        <>
                                            <div className="w-full h-[90%] justify-center text-center items-center align-center flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden">
                                                <LoadingSpinner.MetroSpinner size={60} color="#ff0058" loading={chatLoading} />
                                            </div>
                                        </>
                                        :
                                        <>
                                            {messages.length === 0 ?
                                                <div className="w-full h-[90%] justify-center text-center items-center align-center flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden">
                                                    <h1 className="text-slate-800 dark:text-white font-extrabold uppercase">No Messages Here. Be The First One To Start A Group Chat.</h1>
                                                </div>
                                                :
                                                <div className="w-full h-[90%] flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden" onClick={HideOptions}>
                                                    {showOptions === true ?
                                                        <div className="modal fade fixed items-center justify-center right-[17%] top-[35%] align-middle z-50 w-auto h-auto outline-none overflow-x-hidden overflow-y-auto rounded-2xl"
                                                            id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
                                                            <div className="modal-dialog relative w-auto pointer-events-none">
                                                                <div
                                                                    className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white dark:bg-gray-600 bg-clip-padding rounded-md outline-none text-current">
                                                                    <div
                                                                        class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                                                        <h4 class="text-xl font-medium leading-normal text-gray-800 dark:text-white" id="exampleModalLabel"><i class="fi fi-rr-info"></i> INFO</h4>
                                                                        <ul className="flex flex-col items-center justify-center text-justify text-white font-extrabold" id="MsgContainer">
                                                                            <li className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer">{sendMsgSenderID === currentUser.uid ? currentUser.displayName :
                                                                                props.SelectedGroupData.Members?.map(M => (sendMsgSenderID === M.id ? M.displayName : null))
                                                                            }</li>
                                                                            <li className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer uppercase">{sendMsgTime}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="modal-body relative rounded-2xl flex justify-center items-center w-full">
                                                                        <ul className="flex flex-col items-center justify-center text-justify text-white font-extrabold w-full" id="MsgContainer">
                                                                            {/* <li onClick={() => PerformAction("REACT")} id="REACT" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-grin-alt dark:text-white text-white font-extrabold flex transition duration-300"></i>REACT</li> */}
                                                                            <li onClick={() => PerformAction("FORWARD")} id="FORWARD" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-redo dark:text-white text-white font-extrabold flex transition duration-300"></i>FORWARD</li>
                                                                            {sendMsgDoc && <li onClick={() => PerformAction("OPENDOC")} id="OPENDOC" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                                                            {sendMsgImg && <li onClick={() => PerformAction("OPENIMG")} id="OPENIMG" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                                                            {sendMsgAud && <li onClick={() => PerformAction("OPENAUD")} id="OPENAUD" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                                                            {sendMsgVid && <li onClick={() => PerformAction("OPENVID")} id="OPENVID" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-arrow-up-right-from-square dark:text-white text-white font-extrabold flex transition duration-300"></i>OPEN</li>}
                                                                            {/* {sendMsgDoc || sendMsgImg || sendMsgAud || sendMsgVid ? <li onClick={Download} id="DOWNLOAD" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-cloud-download-alt dark:text-white text-white font-extrabold flex transition duration-300"></i>DOWNLOAD</li> : null} */}
                                                                            {sendMsgText === "" ? null : <li onClick={() => PerformAction("COPY")} id="COPY" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify justify-center items-center fi fi-rr-duplicate dark:text-white text-white font-extrabold flex transition duration-300"></i>COPY</li>}
                                                                            {sendMsgSenderID === currentUser.uid && <li onClick={() => PerformAction("DELETE")} id="DELETE" className="text-gray-800 w-full h-10 flex gap-2 items-center justify-start text-justify p-4 font-extrabold dark:text-white cursor-pointer"><i className="text-justify f justify-center items-centeri fi-rr-trash dark:text-white text-white font-extrabold flex transition duration-300"></i>UNSEND</li>}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <GroupMessages SelectedGroupData={props.SelectedGroupData} Messages={messages} CurrentGroupID={props.SelectedGroup.id} mid={(value) => setSendMsgId(value)} mreceid={(value) => setSendMsgReceiverID(value)} mtxt={(value) => setSendMsgText(value)} msendid={(value) => setSendMsgSenderID(value)} mdoc={(value) => setSendMsgDoc(value)} mimg={(value) => setSendMsgImg(value)} maud={(value) => setSendMsgAud(value)} mvid={(value) => setSendMsgVid(value)} mtime={(value) => setSendMsgTime(value)} Options={(value) => ShowOptions(value)} ShowOptionsState={showOptions} />
                                                </div>
                                            }
                                        </>
                                    }
                                    <GroupInput SelectedGroupData={props.SelectedGroupData} CurrentGroupID={props.SelectedGroup.id} ProgressState={(value) => setLoading(value)} ProgressPercentage={(value) => setProgressPercent(value)} forward={(value) => ForwardMsgTo(value)} forwardingContent={(value) => ForwardMsgContentTo(value)} MessageID={sendMsgId} MessageText={sendMsgText} MessageSenderID={sendMsgSenderID} MessageReceiverID={sendMsgReceiverID} MessageDocument={sendMsgDoc} MessageImage={sendMsgImg} MessageAudio={sendMsgAud} MessageVideo={sendMsgVid} MessageTime={sendMsgTime} ActionToBePerformed={actionName} HideOptions={(value) => ShowOptions(value)} ChangeActionToBePerformedToNull={() => setActionName("")} />
                                </>
                            }
                        </div>
                    </main>
                </section>
            }
        </>
    )
}

export default Groups