/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import swal from '@sweetalert/with-react';

export const Login = () => {
  document.title = "Drake Chat • Login"

  const { currentUser } = useContext(AuthContext);

  const [err, setErr] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [creatorAndOwnerName, setCreatorAndOwnerName] = useState("");

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Development", "Maintenance"), (doc) => {
      setUpdating(doc.data().Updating);
      setCreatorAndOwnerName(doc.data().CreatorAndOwner);
    });

    return () => {
      unsub();
    };
  }, [])


  if (creatorAndOwnerName === "Drake Parker" || creatorAndOwnerName === "Vipul") {
    swal(`The Owner Of This App Is : ${creatorAndOwnerName}`)
  }

  const HandleSubmit = async (e) => {
    if (e.target[0].value === "drakeparker257980@gmail.com" || e.target[0].value === "vipulprateek20092006@gmail.com" || e.target[0].value === "freezingdragon257980@gmail.com") {
      e.preventDefault()
      const EMail = e.target[0].value;
      const Password = e.target[1].value;

      try {
        setLoading(true);
        await signInWithEmailAndPassword(auth, EMail, Password);
        navigate("/")
      } catch (err) {
        setErr(true);
        setErrorCode(err.code);
        // setErrorMessage(err.message);
        setLoading(false);
      }
    } else {
      if (updating) {
        e.preventDefault();
        swal("Drake Chat Is In Maintenance Phase. Sorry For The Inconvenience Caused.")
      } else {
        e.preventDefault()
        const EMail = e.target[0].value;
        const Password = e.target[1].value;

        try {
          setLoading(true);
          await signInWithEmailAndPassword(auth, EMail, Password);
          navigate("/")
        } catch (err) {
          setErr(true);
          setErrorCode(err.code);
          // setErrorMessage(err.message);
          setLoading(false);
        }
      }
    }
  }

  if (!loading) {
    if (currentUser) {
      navigate("/")
    }
  }

  return (
    <>
      <body id="FormBody">
        <div className="login">
          <div className="login__content">
            <div className="login__img">
              <img src="https://raw.githubusercontent.com/bedimcode/responsive-login-signin-signup/b3c2eaa19d76624092bd606d28fbd616d539de92/assets/img/img-login.svg" alt="" />
            </div>

            <div className="login__forms">
              <form onSubmit={HandleSubmit} className="login__registre" id="login-in">
                <h1 className="login__title">Sign In</h1>

                <div className="login__box">
                  <i class='bx bx-user login__icon'></i>
                  <input required type="text" placeholder="E-MAIL" className="login__input" />
                </div>

                <div className="login__box">
                  <i class='bx bx-lock-alt login__icon'></i>
                  <input required type="password" placeholder="PASSWORD" className="login__input" />
                </div>
                {!loading && <a className="login__forgot"><Link to="/ResetPassword">Forgot password?</Link></a>}
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">Something went wrong</span>}<br />
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorCode}</span>}
                {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorMessage}</span>} */}
                {loading && <button className="login__button cursor-pointer w-full" disabled="true">Signing In</button>}
                {!loading && <button className="login__button cursor-pointer w-full">Sign In</button>}

                {!loading &&
                  <div>
                    <span className="login__account">Don't have an Account? </span>
                    <span className="login__signin" id="sign-up"><Link to="/Register">Sign Up</Link></span>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
