import React, { useContext, useState } from 'react';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { updatePassword, signOut } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import swal from '@sweetalert/with-react';
import { auth } from "../firebase";

export const ChangePassword = (props) => {
    // document.title = "Drake Chat • Change Password"

    if (props.SendMsgCount === 0) {
        document.title = "Drake Chat • Change Password"
    } else {
        document.title = "(" + props.SendMsgCount + ") Drake Chat • Change Password"
    }

    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [email, setEmail] = useState(null);
    const [oldPass, setOldPass] = useState(null);

    const HandleFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const NewPassword = e.target[0].value;

        const sleep = ms => new Promise(
            resolve => setTimeout(resolve, ms)
        );

        updatePassword(currentUser, NewPassword)
            .then(async () => {
                await updateDoc(doc(db, "Users", currentUser.uid), {
                    Password: NewPassword,
                });
                setLoading(false);
                setShowAlert(true);
                await sleep(2000);
                // props.onChange("Home")
            })
            .catch((error) => {
                setErr(true);
                setShowAlert(false);
                setLoading(false);
                setErrorCode(error.code);
                setErrorMessage(error.message);
                if (error.code === "auth/requires-recent-login") {
                    swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
                    signOut(auth)
                    window.location.reload()
                    //     setErr(false);
                    //     setErrorCode(null);

                    //     const user = auth.currentUser
                    //     const credential = auth.EmailAuthProvider.credential(
                    //         user.email,
                    //         oldPass
                    //     )

                    //     swal("Please Enter Your E-Mail ID", {
                    //         content: "input"
                    //     }).then((email) => {
                    //         setEmail(`${email}`)
                    //         swal("Please Enter Your Old Password", {
                    //             content: "input"
                    //         }).then((pass) => {
                    //             setOldPass(`${pass}`)
                    //             // try {
                    //             // signInWithEmailAndPassword(auth, email, oldPass);
                    //             // } catch (err) {
                    //             //     setErr(true);
                    //             //     setErrorCode(err.code);
                    //             //     setErrorMessage(err.message);
                    //             //     setLoading(false);
                    //             // }
                    //             user.reauthenticateWithCredential(credential).then(() => {
                    //                 alert("DONE")
                    //             }).catch((error) => {
                    //                 alert("NOT DONE")
                    //             });
                    //         });
                    //     });
                }
            });
    }

    if (!currentUser) {
        navigate("/")
    }

    return (
        <>
            <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4">
                <header className="flex justify-between px-2 md:px-4 lg:px-6">
                    <div className="flex gap-8 align-center justify-center text-center">
                        <span className="my-auto cursor-pointer " id="close-chat" onClick={() => props.onChange("Home")}>
                            <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
                        </span>
                    </div>
                </header>
                <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 !pr-2 rounded-2xl md:p-4 lg:p-6 justify-center align-center text-center items-center">
                    {!loading && showAlert &&
                        <div class="bg-transparent text-center py-4 lg:px-4 rounded-full">
                            <div class="p-4 bg-indigo-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                {/* <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span> */}
                                <span class="font-semibold mr-2 text-left flex-auto">Password Has Been Updated</span>
                                {/* <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg> */}
                            </div>
                        </div>
                    }
                    <div className="w-full h-full relative">
                        <div className="w-full h-[90%] flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden justify-center align-center text-center items-center">
                            <h1 className="font-extrabold text-base md:text-xl break-all whitespace-nowrap dark:text-white">UPDATE PASSWORD</h1>
                            <form onSubmit={HandleFormSubmit} className="justify-center align-center text-center items-center flex flex-col p-8 w-full" style={{ "border-radius": "44px", "background-color": "rgba(0,0,0,0.2)", "backdrop-filter": "blur('44px')" }}>
                                <input type="password" placeholder="PLEASE ENTER A NEW PASSWORD" required className="rounded-full w-full p-2 active:border-none focus:border-none" style={{ justifyContent: "center", alignItems: "center", textAlign: "center", border: "none", backgroundColor: "rgba(255,255,255,0.2)", backdropFilter: "blur('44px')", outline: "none", color: "#fff", fontWeight: "900" }} id="Input" />
                                {loading && <button disabled="true" className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>UPDATING PASSWORD</button>}
                                {!loading && <button className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>UPDATE PASSWORD</button>}
                                {!loading && err && <span style={{ color: 'red' }}>Something Went Wrong!!</span>}
                                {!loading && err && <span style={{ color: 'red' }}>{errorCode}</span>}
                            </form>
                        </div>
                    </div>
                </main>
            </section>
        </>
    )
}
