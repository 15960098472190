/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { deleteUser, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { doc, updateDoc } from "firebase/firestore";
import swal from '@sweetalert/with-react';

export const DeleteUser = (props) => {
    // document.title = "Drake Chat • Delete Account"
    if (props.SendMsgCount === 0) {
        document.title = "Drake Chat • Delete Account"
    } else {
        document.title = "(" + props.SendMsgCount + ") Drake Chat • Delete Account"
    }
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    // const [email, setEmail] = useState(null);
    // const [oldPass, setOldPass] = useState(null);

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const HandleFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const EMAIL = e.target[0].value;
        if (EMAIL === currentUser.email) {
            swal({
                title: "Are You Sure? You Want To Delete The Account",
                text: "Once Deleted, It Can't Be Undone!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    deleteUser(currentUser).then(() => {
                        swal("Account Deleted", {
                            icon: "success",
                        })
                        // .then(() => {
                        // deleteDoc(doc(db, "Users", currentUser.uid));
                        // deleteDoc(doc(db, "UserChats", currentUser.uid));
                        // updateDoc(doc(db, "Users", currentUser.uid), {
                        //     Banned: "True"
                        // });
                        // window.location.reload()
                        // });
                    }).catch((error) => {
                        setErr(true);
                        setShowAlert(false);
                        setLoading(false);
                        setErrorCode(error.code);
                        setErrorMessage(error.message);
                        if (error.code === "auth/requires-recent-login") {
                            swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
                            signOut(auth)
                            window.location.reload()
                        }
                    });
                } else {
                    setShowAlert(false);
                    setLoading(false);
                }
            });
        } else {
            setShowAlert(false);
            setLoading(false);
            swal({
                title: "E-Mail ID Does Not Match",
                text: "Please Enter The Correct E-Mail ID For Initiating The Delete Account Process!",
                icon: "warning"
            })
        }
        // auth()
        //     .deleteUser(currentUser.uid)
        //     .then(() => {
        //         setLoading(false)
        //         navigate("/")
        //     })
        //     .catch((error) => {
        //         setErr(true);
        //         setShowAlert(false);
        //         setLoading(false);
        //         setErrorCode(error.code);
        //         setErrorMessage(error.message);
        //         if (error.code === "auth/requires-recent-login") {
        //             alert("Authorization Credentials Expired, Signing Out. Please Sign In Again")
        //             signOut(auth)
        //             window.location.reload()
        //         }
        //     });
        // navigate("/")
    }

    if (!currentUser) {
        navigate("/")
    }

    return (
        // <>
        //     <div className="DeleteUser">
        //         <div className="DeleteUserContainer">
        //             <button id="BackButton" onClick={() => navigate(-1)}>Go Back</button>
        //             <h1>Update E-Mail Id</h1>
        //             <form onSubmit={HandleFormSubmit}>
        //             <input type="text" placeholder ="Enter Your E-Mail Id For Confirmation" required/>
        //             {loading && <button disabled="true">Deleting Account</button>}
        //             {!loading && <button style={{"background-color":"#ff0058"}}>Delete Account</button>}
        //             {err && <span style={{color:'red'}}>Something Went Wrong!!</span>}
        //             </form>
        //         </div>
        //     </div>
        // </>
        <>
            <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4">
                <header className="flex justify-between px-2 md:px-4 lg:px-6">
                    <div className="flex gap-8 align-center justify-center text-center">
                        <span className="my-auto cursor-pointer " id="close-chat" onClick={() => props.onChange("Home")}>
                            <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
                        </span>
                    </div>
                </header>
                <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 !pr-2 rounded-2xl md:p-4 lg:p-6 justify-center align-center text-center items-center">
                    {/* {showAlert &&
                        <div class="bg-transparent text-center py-4 lg:px-4 rounded-full">
                            <div class="p-4 bg-indigo-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="font-semibold mr-2 text-left flex-auto">Username Has Been Updated</span>
                            </div>
                        </div>
                    } */}
                    <div className="w-full h-full relative">
                        <div className="w-full h-[90%] flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden justify-center align-center text-center items-center">
                            <h1 className="font-extrabold text-base md:text-xl break-all whitespace-nowrap dark:text-white">DELETE ACCOUNT</h1>
                            <form onSubmit={HandleFormSubmit} className="justify-center align-center text-center items-center flex flex-col p-8 w-full" style={{ "border-radius": "44px", "background-color": "rgba(0,0,0,0.2)", "backdrop-filter": "blur('44px')" }}>
                                <input type="email" placeholder="PLEASE ENTER YOUR E-MAIL ID" required className="rounded-full w-full p-2 active:border-none focus:border-none" style={{ justifyContent: "center", alignItems: "center", textAlign: "center", border: "none", backgroundColor: "rgba(255,255,255,0.2)", backdropFilter: "blur('44px')", outline: "none", color: "#fff", fontWeight: "900" }} id="Input" />
                                {loading && <button disabled="true" className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>DELETING ACCOUNT</button>}
                                {!loading && <button className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>DELETE ACCOUNT</button>}
                                {!loading && err && <span style={{ color: 'red' }}>Something Went Wrong!!</span>}
                                {!loading && err && <span style={{ color: 'red' }}>{errorCode}</span>}
                            </form>
                        </div>
                    </div>
                </main>
            </section>
        </>
    )
}
